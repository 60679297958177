/**
 * Для совместимости с IE 11 использован рецепт
 * https://github.com/JeffreyWay/laravel-mix/issues/2174
 */
import 'babel-polyfill';
/**
 * Общие для большинства проектов зависимости
 */
global.jquery = global.jQuery = global.$ = require('jquery');
require('jquery-ui');
require('bootstrap');
require('suggestions-jquery');
require('select2/dist/js/select2.full');
require('jquery.maskedinput/src/jquery.maskedinput');
require('jquery.equalheights');
require('@fancyapps/fancybox');
require('eonasdan-bootstrap-datetimepicker');
//require('webauthn-simple-app');
global.likely = require('ilyabirman-likely');
require('loading-attribute-polyfill');
//global.jstz = require('jstimezonedetect');

try {
    require('slick-carousel');
} catch (e) {
}

/**
 * Календарь moment
 */
//import moment from "moment";
try {
    var moment = require('moment');
    moment().format();
    require("moment/locale/ru");
    moment.locale("ru");
} catch (e) {
}

/**
 * Пуш сервис
 */
import Echo from "laravel-echo";
//global.Echo = Echo || {};
global.io = require('socket.io-client');


/**
 * подключение к нашему пуш-сервису
 * @link https://laravel.com/docs/5.8/broadcasting#installing-laravel-echo
 * @type {Echo}
 */
if (typeof io !== 'undefined') {
    console.log('Connecting Echo');
    window.Echo = new Echo({
        broadcaster: 'socket.io',
        authEndpoint: '/broadcasting/auth'
    });
}
